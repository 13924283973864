<template>
    <div
        class="page-flag-start iphonex-pb"
        :class="type == 1 ? 'flag' : 'birthday'"
        v-show="show"
    >
        <headBar
            title=""
            left-arrow
            background="transparent"
            @click-left="newAppBack"
            :placeholder="false"
        />
        <img
            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/page-start-head1.png"
            class="head1"
            alt=""
            v-if="type == 1"
        />
        <img
            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/page-start-birthday.png"
            class="head2"
            alt=""
            v-else
        />
        <div class="pub_onefull fbox">
            <div class="pub_onefull ctbox">
                <div class="gift-info">
                    <div class="t1">
                        开始前先许愿，让我们帮你实现
                    </div>
                    <div class="t2">
                        以下礼品任选一项，生日当天可能会在你的账户以优惠券形式惊喜出现！
                    </div>
                </div>
                <div class="gift-box">
                    <div class="gift-list">
                        <div
                            class="gift-model"
                            v-for="(item, index) in list"
                            :key="index"
                            :class="{ active: acindex == index }"
                            @click="acindex = index"
                        >
                            <div class="img"><img :src="item.giftIcon" /></div>
                            <div class="label">{{ item.giftName }}</div>
                        </div>
                    </div>
                </div>
                <div
                    class="cl-button small f32 fw5 row-center-center sub"
                    @click="sub"
                    :class="{ disabled: acindex == -1 }"
                    v-if="show"
                >
                    我选好了，开始吧！
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newAppBack, initBack } from '@/lib/appMethod';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
export default {
    mixins: [userMixin],
    components: {
        headBar,
    },
    data() {
        return {
            acindex: -1,
            list: [],
            show: false,
            type: this.$route.query.type,//1 虎年flag
        };
    },
    methods: {
        newAppBack,
        getHealthInfo() {
            this.$axios
                .post(
                    this.baseURLApp +
                        '/user/health/data/health/birthday/status',
                    {
                        userId: this.userId,
                        token: this.token,
                    }
                )
                .then((res) => {
                    console.log(res);
                    if (res.code === '1') {
                        if (res.data == '1') {
                            this.$router.replace('page-1');
                        } else {
                            if (this.type == 1) {
                                //虎年flag
                                this.$router.replace('page-data');
                            } else {
                                //生日
                                this.$router.replace('page-success');
                            }
                        }
                    }
                });
        },
        getBirthDayGift() {
            this.$axios
                .post(this.baseURLApp + '/birthdaygift/list', {
                    userId: this.userId,
                    token: this.token,
                })
                .then((res) => {
                    console.log(res);
                    if (res.code === '1') {
                        this.list = res.data;
                    }
                });
        },
        sub() {
            this.$store.commit('setFlagJson', {
                giftId: this.list[this.acindex].giftId,
                type: this.type || '',
            });
            this.$router.push('/superdeer-activity/flag-202201/page-1');
            this.countPoint('43', '311', '1471');
        },
    },
    async created() {
        // initBack();
        await this.$getAllInfo();
        // this.getBirthDayGift();
        this.getHealthInfo();
        this.countPoint('43', '311', '1470');
    },
};
</script>
<style lang="less" scoped>
@import url(./index.less);
.page-flag-start {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    img {
        max-width: 100%;
        vertical-align: top;
    }
    .head1 {
        height: 446px;
    }
    .head2 {
        height: 522px;
    }
    &.flag {
        background: #fff1b6;
        .fbox {
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/page-start-head2.png)
                no-repeat;
            background-size: 100% auto;
        }
    }
    &.birthday {
        background: #fee8c7;
        .fbox {
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/page-start-birthday2.png)
                no-repeat;
            background-size: 100% auto;
            margin-top: -56px;
        }
    }
    .fbox {
        padding: 0 32px 22px 32px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .flag-title {
            color: #011465;
            font-size: 20px;
            font-weight: bold;
            padding-left: 10px;
            margin-bottom: 24px;
        }
        .ctbox {
            overflow: hidden;
            background: #fff;
            border-radius: 24px;
            padding-bottom: 36px;
            display: flex;
            flex-direction: column;
            .gift-info {
                padding: 60px 40px 32px;
                .t1 {
                    color: #242831;
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 32px;
                    position: relative;
                }
                .t2 {
                    font-size: 24px;
                    color: #6c727a;
                    margin-top: 24px;
                    line-height: 34px;
                }
            }
            .gift-box {
                flex: 1;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
                margin-bottom: 36px;
            }
            .gift-list {
                display: flex;
                flex-wrap: wrap;
                padding: 0 32px;
                margin: 0 auto;
                .gift-model {
                    overflow: hidden;
                    width: 184px;
                    .img {
                        width: 184px;
                        height: 184px;
                        border-radius: 24px;
                        background: #f9fafb;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .label {
                        font-size: 22px;
                        color: #3c454e;
                        font-weight: bold;
                        text-align: center;
                        margin: 20px 0;
                        word-wrap: break-word;
                    }
                    &:nth-child(3n + 2) {
                        margin: 0 28px;
                    }
                }
                .active {
                    .img {
                        border: 2px solid #242831;
                    }
                }
            }
            .sub {
                margin: 0 auto;
            }
        }
    }
}
</style>
